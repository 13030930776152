<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
    <div class="col-12" style="padding: 0 !important;">
        <MarketHeader :data="$route.params.name"></MarketHeader>     
    </div>
    <MarketNews></MarketNews>
</div>
</template>

<script>
// این ویو برای ماژول  بازار  پروفایل شاخص ، بخش اولیه مورد استفاده قرار میگیرد
import MarketNews from "@/components/Mobile/Market/Profile/News";
import marketNavPage from "@/assets/static_market_nav_page";
import MarketHeader from '@/components/Mobile/Market/Profile/Header';

export default {
    name: "marketNews",
    components: {
        MarketNews,
        MarketHeader,
    },
    data: function () {
        return {
            title: "--",
            symbol: "--",
            marketNavPage,
            tabName:'MarketNews',
        };
    },
    mounted() {
    },
};
</script>
